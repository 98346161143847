class VehicleMaintenance {
    constructor(
        id,
        clientID,
        vehicleID,
        accountID, 
        description
    ) {
        this.id = id,
        this.clientID = clientID,
        this.vehicleID = vehicleID,
        this.accountID = accountID,
        this.description = description
    }
}

export const vehicleMaintenanceDefault = () => {
    const maintenance = {
        id: 0,
        clientID: 0,
        vehicleID: 0,
        accountID: 0,
        description: ""
    }
    return maintenance
}

export default VehicleMaintenance