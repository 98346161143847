class EquipmentHistory{
    constructor(
        id,
        clientID,
        vehicleID,
        equipmentID,
        createdDate,
        description,
        modelID,
        clientName,
        plate,
        equipmentName
    ) {
        this.id = id,
        this.clientID = clientID,
        this.vehicleID = vehicleID,
        this.equipmentID = equipmentID,
        this.createdDate = createdDate,
        this.description = description,
        this.modelID = modelID,
        this.clientName = clientName,
        this.plate = plate,
        this.equipmentName = equipmentName
    }
}

export const equipmentHistoryDefault = () => {
    const equipmentHistory = {
        id: 0,
        clientID: null,
        vehicleID: null,
        equipmentID: 0,
        createdDate: 0,
        description: "",
        modelID: 0,
        clientName: null,
        plate: 0,
        equipmentName: null
    }
    return equipmentHistory
}

export default EquipmentHistory