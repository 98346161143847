var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"astra-table",attrs:{"dense":"","height":"76vh","fixed-header":"","headers":_vm.controller.headers,"options":_vm.controller.options,"server-items-length":_vm.controller.vehicleMaintenancePagination.total,"items":_vm.controller.vehicleMaintenancePagination.items,"loading":_vm.controller.loading,"footer-props":{
            'items-per-page-options': [40, 80],
        }},on:{"update:options":function($event){return _vm.$set(_vm.controller, "options", $event)}},scopedSlots:_vm._u([{key:"item.isolated",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","hide-details":"","readonly":"","dense":""},model:{value:(item.isolated),callback:function ($$v) {_vm.$set(item, "isolated", $$v)},expression:"item.isolated"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',[(_vm.hadPermission(1,0))?_c('v-btn',{staticClass:"px-2",attrs:{"icon":""},on:{"click":function($event){return _vm.controller.delete(item)}}},[_c('v-icon',{attrs:{"color":"deleteBtn","small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-3",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown
                        ? 'py-3 d-flex align-center justify-center'
                        : 'd-flex align-center',attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"table-title"},[_vm._v("Registro de atendimentos")])]),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{staticClass:"px-2 search-tf",staticStyle:{"max-width":"250px"},attrs:{"outlined":"","color":"primary","hide-details":"","dense":"","placeholder":"Pesquisar..."},model:{value:(_vm.controller.search),callback:function ($$v) {_vm.$set(_vm.controller, "search", $$v)},expression:"controller.search"}}),_c('v-btn',{staticClass:"action-btn d-flex justify-center white--text",attrs:{"height":"40","width":"120","color":"green","tile":"","loading":_vm.controller.loadingBtnExcel},on:{"click":function($event){$event.stopPropagation();return _vm.controller.exportExcel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel-outline")]),_vm._v(" Excel ")],1)],1)],1)]},proxy:true},{key:"item.createdDate",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.createdDate).format("DD/MM/YYYY HH:mm"))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }