<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <!-- <div class="item">Em breve 1</div> -->

      <v-row>
        <v-col v-if="hadPermission(0,0)">
          <div
            style="height: 200px"
            class="item"
            @click="controller.requestPage(2)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-barcode-scan</v-icon>
              <h2>Redes</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(0,0)">
          <div
            class="item"
            style="height: 200px"
            @click="controller.requestPage(3)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-account-group</v-icon>
              <h2>Clientes</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(3,4)">
          <div
            class="item"
            style="height: 200px"
            @click="controller.requestPage(1)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-inbox</v-icon>
              <h2>Modelos</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(4,4)">
          <div
            class="item"
            style="height: 200px"
            @click="controller.requestPage(4)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-truck</v-icon>
              <h2>Veículos</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(2,4)">
          <div
            class="item"
            style="height: 200px"
            @click="controller.requestPage(5)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-radio-tower</v-icon>
              <h2>Equipamentos</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(7,4)">
          <div
            class="item"
            style="height: 200px"
            @click="controller.requestPage(6)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-account</v-icon>
              <h2>Usuários</h2>
            </div>
          </div>
        </v-col>
        <v-col v-if="hadPermission(7,4)">
          <div
            id="menu-activator"
            class="item"
            style="height: 200px"  
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-chart-box-multiple</v-icon>
              <h2 class="ma-2">Histórico</h2>
            </div>
          </div>
          <v-menu activator="#menu-activator">
            <v-list>
              <v-list-item>
                <v-btn
                  id="menu-activator"
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="70"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.requestPage(8)"
                >
                  <v-icon size="24" color="primary">mdi-radio-tower</v-icon>
                </v-btn>
                <span class="mx-2">Relatório equipamentos</span>
              </v-list-item>
              <v-list-item>
                <v-btn
                  id="menu-activator"
                  elevation="0"
                  max-height="40"
                  min-height="0"
                  min-width="0"
                  max-width="70"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="menu-btn"
                  @click="controller.requestPage(9)"
                >
                  <v-icon size="24" color="primary">mdi-truck</v-icon>
                </v-btn>
                  <span class="mx-2">Registro de atendimentos</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col >
          <div
            class="item mt-5"
            style="height: 200px"
            @click="controller.requestPage(10)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                size="60"
                color="primary"
              >mdi-dots-grid</v-icon>
              <h2>Painel de Gestão</h2>
            </div>
          </div>
        </v-col>
      </v-row> -->

      <!-- <div class="item">Em breve 7</div> -->
    </div>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="dash-container-mobile"
    >
      <div
        class="item-mobile"
        @click="controller.requestPage(2)"
        v-if="hadPermission(0,0)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-barcode-scan</v-icon>
          <h3>Redes</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(0,0)"
        @click="controller.requestPage(3)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-account-group</v-icon>
          <h3>Clientes</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(3,4)"
        @click="controller.requestPage(1)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-inbox</v-icon>
          <h3>Modelos</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(7,4)"
        @click="controller.requestPage(6)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-account</v-icon>
          <h3>Usuários</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(4,4)"
        @click="controller.requestPage(4)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-truck</v-icon>
          <h3>Veículos</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(2,4)"
        @click="controller.requestPage(5)"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            size="40"
            color="primary"
          >mdi-radio-tower</v-icon>
          <h3>Equipamentos</h3>
        </div>
      </div>
      <div
        class="item-mobile"
        v-if="hadPermission(7, 4)"
      >
        <div id="menu-activator">
          <div
            class="d-flex flex-column align-center"
          >
            <v-icon
              size="40"
              color="primary"
            >mdi-chart-box-multiple</v-icon>
            <h2>Histórico</h2>
          </div>
        </div>
        <v-menu activator="#menu-activator">
          <v-list>
            <v-list-item>
              <v-btn
                id="menu-activator"
                elevation="0"
                max-height="40"
                min-height="0"
                min-width="0"
                max-width="70"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                class="menu-btn"
                @click="controller.requestPage(8)"
              >
                <v-icon size="24" color="primary">mdi-radio-tower</v-icon>
              </v-btn>
              <span class="mx-2">Relatório equipamentos</span>
            </v-list-item>
            <v-list-item>
              <v-btn
                id="menu-activator"
                elevation="0"
                max-height="40"
                min-height="0"
                min-width="0"
                max-width="70"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                class="menu-btn"
                @click="controller.requestPage(9)"
              >
                <v-icon size="24" color="primary">mdi-truck</v-icon>
              </v-btn>
              <span class="mx-2">Registro de atendimentos</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- <div
        class="item-mobile"
        @click="controller.requestPage(10)"
      >
        <div
          class="d-flex flex-column align-center" 
        >
          <v-icon
            size="40"
            color="primary"
          >mdi-dots-grid</v-icon>
          <h3>Painel de Gestão</h3>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { hadPermission } from "@/core/domain/model/permission";
import { dashboardController } from "@/di/di";

export default {
  data: (context) => ({
    controller: dashboardController(context),
  }),
};
</script>

<style scoped>
.dash-container-desktop {
  height: 89vh;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 40px;
}

.item:nth-child(1) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 5;
  grid-row: 1 / 4;
}
.item:nth-child(1):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}
.item:nth-child(2) {
  background-color: transparent;
  grid-column: 1 / 5;
  grid-row: 4 / 5;
}
.item:nth-child(3) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 5 / 8;
}
.item:nth-child(3):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}
.item:nth-child(4) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 5 / 8;
}
.item:nth-child(4):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}
.item:nth-child(5) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 3 / 4;
  grid-row: 5 / 8;
}
.item:nth-child(5):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}
.item:nth-child(6) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 4 / 5;
  grid-row: 5 / 8;
}
.item:nth-child(6):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}

.item:nth-child(7) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 5 / 7;
  grid-row: 1 / 8;
}

.item:nth-child(8) {
  background-color: var(--v-secondary-base);
  border: 3px solid var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 5 / 8;
}
.item:nth-child(8):hover {
  box-shadow: 0 0 12px var(--v-primary-base);
}

.dash-container-mobile {
  height: 89vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 16px;
}

.item-mobile:nth-child(1) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  min-height: 120px;
}
.item-mobile:nth-child(2) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  min-height: 120px;
}
.item-mobile:nth-child(3) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  min-height: 120px;
}
.item-mobile:nth-child(4) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  min-height: 120px;
}
.item-mobile:nth-child(5) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  min-height: 120px;
}
.item-mobile:nth-child(6) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  min-height: 120px;
}
.item-mobile:nth-child(7) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  min-height: 120px;
}
/* .item-mobile:nth-child(8) {
  background-color: var(--v-secondary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  min-height: 120px;
} */
/* second row with 250px instead of 100%  */
/* .col{
  max-width: 250px;
} */
</style>
