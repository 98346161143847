export const headers = [
  {
    text: 'Nome',
    align: 'start',
    sortable: true,
    value: 'name',
    width: '25%'
  },
  {
    text: 'Email',
    align: 'start',
    sortable: true,
    value: 'email',
    width: '25%'
  },
  {
    text: 'Grupo Usuário',
    align: 'start',
    sortable: true,
    value: 'accountGroupName',
    width: '20%'
  },
  {
    text: 'Cliente',
    align: 'start',
    sortable: true,
    value: 'clientName',
    width: '15%'
  },
  {
    text: 'Status do usuário',
    align: 'start',
    sortable: true,
    value: 'active',
    width: '5%'
  },
  {
    text: 'Ações',
    value: 'actions',
    align: 'right',
    sortable: false,
    width: '10%'
  }
]

