export const headers = [
    {
        text: 'Data de criação',
        align: 'start',
        sortable: true,
        value: 'createdDate'
        // width: '15%'
    },
    {
        text: 'Descrição',
        align: 'start',
        sortable: true,
        value: 'description'
    },
    {
        text: 'Modelo',
        align: 'start',
        sortable: true,
        value: 'model'
    },
    {
        text: 'Nome do cliente',
        align: 'start',
        sortable: true,
        value: 'clientName'
    },
    {
        text: 'Placa',
        align: 'start',
        sortable: true,
        value: 'plate'
    },
    {
        text: 'Nome do equipamento',
        align: 'start',
        sortable: true,
        value: 'equipmentName'
    },
    {
        text: 'Ação',
        align: 'start',
        sortable: true,
        value: 'action',
        width: '5%'
    },
]