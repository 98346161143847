import { snackbar } from '@/core/controller/snackbar_controller';
import { getAccountGroupID, getAccountName } from '@/core/domain/model/jwtAuthToken';
import DashboardPage from '@/module/dashboard/view/dashboard_page.vue'
import ModelPage from '@/module/model/view/model_page.vue'
import CodePage from '@/module/code/view/code_page.vue'
import ClientPage from '@/module/client/view/client_page.vue'
import VehiclePage from '@/module/vehicle/view/vehicle_page.vue'
import EquipmentPage from '@/module/equipment/view/equipment_page'
import AccountPage from '@/module/account/view/account_page'
import ReleasePage from '@/module/release/view/release_page'
import EquipmentHistoryPage from '@/module/equipmentHistory/view/equipmentHistory_page.vue';
import vehicleMaintenancePage from '@/module/vehicleMaintenance/view/vehicleMaintenance_page.vue';
import { bus } from '@/main'
import Vue from 'vue';

class HomeController {
  context = null;
  selected = null;
  dark = true
  userName = ""
  loading = true;
  search = ""
  colapsed = true
  constructor(
    context,
    fetchAccountPermissionsUseCase
  ) {
    this.context = context;
    this.fetchAccountPermissionsUseCase = fetchAccountPermissionsUseCase
  }

  async navigate(page) {
    switch (page) {
      case 0:
        this.selected = DashboardPage
        break
      case 1:
        this.selected = ModelPage
        break
      case 2:
        this.selected = CodePage
        break
      case 3:
        this.selected = ClientPage
        break
      case 4:
        this.selected = VehiclePage
        break
      case 5:
        this.selected = EquipmentPage
        break
      case 6:
        this.selected = AccountPage
        break
      case 7:
        this.selected = ReleasePage
        break
      case 8:
        this.selected = EquipmentHistoryPage
        break
      case 9:
        this.selected = vehicleMaintenancePage
        break
      default:
        break
    }
  }

  logout() {
    localStorage.removeItem('auth');
    window.router.login()
  }

  async mounted() {
    this.busListener()
    try {

      const accountGroupID = getAccountGroupID()

      Vue.prototype.permissions = await this.fetchAccountPermissionsUseCase(
        accountGroupID
      );

      this.dark = localStorage.getItem("dark") === "true" ? true : false;
      window.navigation = this;
      setTimeout(() => {
        this.loading = false
      }, 500)
      this.userName = getAccountName()
      this.navigate(0)
    } catch (error) {
      snackbar.show({ message: error.toString() })
    }
  }

  busListener() {
    bus.$on('toPage', (id) => {
      this.navigate(id)
    })
  }

}

export default HomeController