<template>
  <div>
    <v-data-table
      class="astra-table"
      dense
      height="74vh"
      fixed-header
      :headers="controller.headers"
      :options.sync="controller.options"
      :server-items-length="controller.vehiclePagination.total"
      :items="controller.vehiclePagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox
          color="primary"
          class="mt-0 pt-0"
          v-model="item.active"
          hide-details
          readonly
          dense
        ></v-checkbox>
      </template>

      <template v-slot:[`item.isolated`]="{ item }">
        <v-checkbox
          color="primary"
          class="mt-0 pt-0"
          v-model="item.isolated"
          hide-details
          readonly
          dense
        ></v-checkbox>
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">  
          {{ dayjs(item.createdDate).format("DD/MM/YYYY - HH:mm") }}  
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            class="px-2"
            v-if="hadPermission(6,1)"
          >
            <v-icon
              color="green"
              @click="controller.openCodeGenerator(item)"
              small
            >mdi-qrcode</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
            v-if="hadPermission(4,2)"
          >
            <v-icon
              color="editBtn"
              small
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.openVehicleMaintenance(item)"
            class="px-2"
            v-if="hadPermission(4,2)"
          >
            <v-icon
              color="green"
              small
            >mdi-history</v-icon>
          </v-btn>
          <!--           <v-btn icon @click="controller.delete(item)" class="px-2">
            <v-icon color="deleteBtn" small>mdi-delete</v-icon>
          </v-btn> -->
        </div>
      </template>

      <template v-slot:top>
        <v-row
          class="pa-3"
          no-gutters
        >
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'py-3 d-flex align-center justify-center'
                : 'd-flex align-center'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <span class="table-title">Veículos</span>
          </v-col>
          <v-col
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'd-flex flex-column align-center justify-center'
                : 'd-flex align-center justify-end'
            "
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div style="width: 200px">
              <v-select
                outlined
                dense
                color="primary"
                v-model="controller.statusSelected"
                :items="controller.statusList"
                hide-details
                class="pa-2 search-tf"
                item-text="text"
                item-value="value"
                @change="controller.changeStatus()"
              ></v-select>
            </div>
            <div style="width: 300px">
              <v-text-field
                class="pa-2 search-tf"
                outlined
                color="primary"
                hide-details
                dense
                placeholder="Pesquisar placa..."
                v-model="controller.search"
              ></v-text-field>
            </div>
            <v-btn
              class="action-btn d-flex justify-center white--text"
              height="40"
              width="120"
              color="green"
              tile
              @click.stop="controller.exportExcel()"
              :loading="controller.loadingBtnExcel"
            >
              <v-icon left>mdi-file-excel-outline</v-icon>
              Excel
            </v-btn>
            <v-dialog
              max-width="600"
              persistent
              scrollable
              v-model="controller.dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2 action-btn d-flex justify-center"
                  height="40"
                  width="120"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  @click.stop="controller.open()"
                  v-if="hadPermission(4,1)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar
                </v-btn>
              </template>
              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Veículo</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form
                    ref="crud"
                    lazy-validation
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="d-flex justify-center"
                      >
                        <v-switch
                          v-model="controller.form.active"
                          inset
                          label="Ativo"
                        ></v-switch>
                        <v-spacer></v-spacer>
                        <v-switch
                          v-model="controller.form.isolated"
                          inset
                          label="Isolado"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="plate"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Placa"
                            v-model="controller.form.plate"
                          />
                          <label for="plate">Placa</label>
                        </div>
                        <div class="form-input-group" v-if="hadPermission(0,0)">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="client"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Cliente"
                            v-model="controller.form.clientID"
                            :search-input.sync="controller.searchClient"
                            :items="controller.clientPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="client">Cliente</label>
                        </div>
                        
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="model"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Modelo"
                            v-model="controller.form.model"
                          />
                          <label for="model">Modelo</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="year"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Ano"
                            v-model="controller.form.year"
                            type="number"
                            counter=""
                            maxlength="4"
                          />
                          <label for="year">Ano</label>
                        </div>
                        <div
                          class="form-input-group"
                        >
                          <v-textarea
                            class="search-tf"
                            single-line
                            id="description"
                            background-color="transparent"
                            outlined
                            dense
                            placeholder="Descrição"
                            v-model="controller.form.description"
                          />
                          <label for="description">Descrição</label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      class="pb-12"
                    >
                      <v-col cols="12">
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="equipment"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Buscar equipamentos"
                            :search-input.sync="controller.searchEquipment"
                            :items="controller.equipmentPagination.items"
                            item-text="name"
                            item-value="id"
                          >
                            <template #item="{ item }">
                              <v-list-item @click="controller.toggleItem(item)">
                                <div>
                                  <v-simple-checkbox
                                    v-ripple
                                    color="accent"
                                    :value="controller.isSelected(item)"
                                    @click="controller.toggleItem(item)"
                                  />
                                </div>
                                <div class="ml-2 d-flex align-center">
                                  <span class="pl-2">{{ item.name }}</span>
                                </div>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                          <label for="equipment">Buscar equipamentos</label>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex flex-column align-center"
                      >
                        <h3>Equipamentos selecionados:</h3>
                        <div
                          class="my-2 d-flex flex-wrap justify-center"
                          v-if="controller.form.equipments.length > 0"
                        >
                          <div
                            v-for="(equip, i) in controller.form.equipments"
                            :key="i"
                            class="pl-4 d-flex align-center equip-chip"
                          >
                            <b>{{ equip.name }}</b>
                            <v-btn
                              @click="controller.removeItem(equip)"
                              icon
                            >
                              <v-icon
                                size="20"
                                color="#ff0000"
                              >mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div v-else>
                          <span>Nenhum equipamento selecionado</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
              <!--              <v-card class="crud-card">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span class="table-title">Veículo</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pt-3 pb-1">
                  <v-form ref="crud" lazy-validation>
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-switch
                          v-model="controller.form.active"
                          inset
                          label="Ativo"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="plate"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Placa"
                            v-model="controller.form.plate"
                          />
                          <label for="plate">Placa</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="client"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Cliente"
                            v-model="controller.form.clientID"
                            :search-input.sync="controller.searchClient"
                            :items="controller.clientPagination.items"
                            item-text="name"
                            item-value="id"
                          />
                          <label for="client">Cliente</label>
                        </div>

                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="model"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Modelo"
                            v-model="controller.form.model"
                          />
                          <label for="model">Modelo</label>
                        </div>
                        <div class="form-input-group">
                          <v-text-field
                            class="search-tf"
                            single-line
                            id="year"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Ano"
                            v-model="controller.form.year"
                            type="number"
                            counter=""
                            maxlength="4"
                          />
                          <label for="year">Ano</label>
                        </div>
                        <div class="form-input-group">
                          <v-autocomplete
                            class="search-tf"
                            single-line
                            id="equipment"
                            background-color="transparent"
                            outlined
                            required
                            dense
                            placeholder="Equipamentos"
                            v-model="controller.form.equipments"
                            :search-input.sync="controller.searchEquipment"
                            :items="controller.equipmentPagination.items"
                            item-text="name"
                            item-value="id"
                            deletable-chips
                            multiple
                            small-chips
                            return-object
                          />
                          <label for="equipment">Equipamentos</label>
                        </div>
                      </v-col>
                      {{ controller.form.equipments }}
                    </v-row>
                  </v-form>
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-actions class="px-6 pb-3">
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    outlined
                    @click="controller.close()"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="action-btn d-flex justify-center"
                    height="36"
                    width="114"
                    color="primary"
                    tile
                    @click="controller.create()"
                    :loading="controller.loading"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card> -->
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <!--     <v-dialog max-width="600" persistent v-model="controller.changeDialog">
      <v-card class="crud-card">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="table-title">Veículo</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-3 pb-1">
          <v-form ref="crud" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center">
                <v-switch
                  v-model="controller.form.active"
                  inset
                  label="Ativo"
                ></v-switch>
              </v-col>
              <v-col>
                <div class="form-input-group">
                  <v-text-field
                    class="search-tf"
                    single-line
                    id="plate"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Placa"
                    v-model="controller.form.plate"
                  />
                  <label for="plate">Placa</label>
                </div>
                <div class="form-input-group">
                  <v-autocomplete
                    class="search-tf"
                    single-line
                    id="client"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Cliente"
                    v-model="controller.form.clientID"
                    :search-input.sync="controller.searchClient"
                    :items="controller.clientPagination.items"
                    item-text="name"
                    item-value="id"
                  />
                  <label for="client">Cliente</label>
                </div>

                <div class="form-input-group">
                  <v-text-field
                    class="search-tf"
                    single-line
                    id="model"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Modelo"
                    v-model="controller.form.model"
                  />
                  <label for="model">Modelo</label>
                </div>
                <div class="form-input-group">
                  <v-text-field
                    class="search-tf"
                    single-line
                    id="year"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Ano"
                    v-model="controller.form.year"
                    type="number"
                    counter=""
                    maxlength="4"
                  />
                  <label for="year">Ano</label>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="pb-12">
              <v-col cols="12">
                <div class="form-input-group">
                  <v-autocomplete
                    class="search-tf"
                    single-line
                    id="equipment"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Buscar equipamentos"
                    :search-input.sync="controller.searchEquipment"
                    :items="controller.equipmentPagination.items"
                    item-text="name"
                    item-value="id"
                    return-object
                  >
                    <template #item="{ item }">
                      <v-list-item @click="controller.toggleItem(item)">
                        <div>
                          <v-simple-checkbox
                            v-ripple
                            color="accent"
                            :value="controller.isSelected(item)"
                            @click="controller.toggleItem(item)"
                          />
                        </div>
                        <div class="ml-2 d-flex align-center">
                          <span class="pl-2">{{ item.name }}</span>
                        </div>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <label for="equipment">Buscar equipamentos</label>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex flex-column align-center">
                <h3>Equipamentos selecionados:</h3>
                <div
                  class="my-2 d-flex flex-wrap justify-center"
                  v-if="controller.form.equipments.length > 0"
                >
                  <div
                    v-for="(equip, i) in controller.form.equipments"
                    :key="i"
                    class="pl-4 d-flex align-center equip-chip"
                  >
                    <b>{{ equip.name }}</b
                    ><v-btn @click="controller.removeItem(equip)" icon
                      ><v-icon size="20" color="#ff0000"
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </div>
                </div>
                <div v-else>
                  <span>Nenhum equipamento selecionado</span>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.changeDialog = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.create()"
            :loading="controller.loading"
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog
      max-width="600"
      persistent
      v-model="controller.dialogGenerateQrCode"
      scrollable
    >
      <v-card class="crud-card">
        <v-card-title class="d-flex justify-center">Gerar senha para o serial {{controller.formCode.serial}}</v-card-title>
        <v-card-text>
          <v-form
            ref="crudGenerateCodeVehicle"
            lazy-validation
          >
            <div class="form-input-group" v-if="controller.equipments.length > 1">
              <v-select
                class="search-tf"
                single-line
                id="equipment"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Equipamento"
                :items="controller.equipments"
                v-model="controller.formCode.equipmentID"
                @change="controller.changeSerialName($event)"
                return-object
                item-text="mac"
                item-value="id"
              />
              <label for="equipment">Equipamento</label>
            </div>
            <div class="form-input-group">
              <v-text-field
                class="search-tf"
                single-line
                id="code"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Rede"
                v-model="controller.formCode.code"
              />
              <label for="code">Rede</label>
            </div>
            <div class="form-input-group">
              <v-select
                class="search-tf"
                single-line
                id="reason"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Motivo"
                v-model="controller.formCode.descriptionReason"
                :items="controller.reason"
              />
              <label for="reason">Motivo</label>
            </div>
            <div class="form-input-group">
              <v-textarea
                class="search-tf"
                id="obs"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                background-color="transparent"
                outlined
                required
                dense
                placeholder="Observação"
                v-model="controller.formCode.description"
              />
              <label for="obs">Observação</label>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogGenerateQrCode = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.generateCode()"
            :loading="controller.loadingUpdateClient"
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="400"
      persistent
      v-model="controller.dialogGenerateQrCodeCreated"
      scrollable
    >
      <v-card
        height="250"
        
      >
        <v-card-text class="text-center d-flex align-center justify-center fill-height flex-column">
          <div class="display-5 my-10">
            <strong>Senha gerada com sucesso!</strong>
            <br>
            <strong>{{ controller.code.reason }}</strong>
          </div>
          <div
            class="display-2"
            style="color: red"
          >
            <strong>{{ controller.code.password }}</strong>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="controller.dialogVehicleMaintenance"
      max-width="500"
    >
      <v-card>
        <v-card-title>Criar novo relatório de atendimento</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="controller.formMaintenance.plate"
            label="Placa"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="controller.formMaintenance.client"
            label="Nome"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="controller.formMaintenance.description"
            label="Observação"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogVehicleMaintenance = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.saveVehicleMaintenance()"
            
          >
            <v-icon left>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

 <script>
import { hadPermission } from "@/core/domain/model/permission";
import { vehicleController } from "@/di/di";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "vehicle-page",
  data: (context) => ({
    controller: vehicleController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
    "controller.clientOptions"() {
      this.controller.watchClientOptions();
    },
    "controller.searchClient": _.debounce(function () {
      this.controller.watchClientSearch();
    }, 500),
    "controller.searchEquipment": _.debounce(function () {
      this.controller.watchEquipmentSearch();
    }, 500),
  },
};
</script> 

<style scoped lang="scss">
.astra-table {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

.toolbar-table {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

::v-deep .v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-secondary-base) !important;
}

.action-btn {
  text-transform: none;
  border-radius: 10px !important;
}

.search-tf {
  border-radius: 10px !important;
}

.table-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.crud-card {
  border-radius: 20px;
  background-color: var(--v-secondary-base) !important;
}

::v-deep .v-dialog {
  border-radius: 20px !important;
}

label {
  order: -1;
}

.v-input:focus-within + label {
  color: var(--v-primary-base) !important;
}

.form-input-group {
  display: flex;
  flex-direction: column;
}

::v-deep tbody {
  tr:hover {
    background-color: var(--v-tertiary-base) !important;
  }
}

.equip-chip {
  background-color: var(--v-tertiary-base);
  border-radius: 8px;
  margin: 2px;
}
</style>
