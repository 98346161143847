<template>
    <div>
      <v-data-table
        class="astra-table"
        dense
        height="76vh"
        fixed-header
        :headers="controller.headers"
        :options.sync="controller.options"
        :server-items-length="controller.equipmentHistoryPagination.total"
        :items="controller.equipmentHistoryPagination.items"
        :loading="controller.loading"
        :footer-props="{
          'items-per-page-options': [40, 80],
        }"
      >

        <template v-slot:[`item.createdDate`]="{ item }">  
          {{ dayjs(item.createdDate).format("DD/MM/YYYY - HH:mm") }}  
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div>
            <v-btn
              icon
              @click="controller.change(item)"
              class="px-2"
              v-if="hadPermission(4,2)"
            >
              <v-icon
                color="editBtn"
                small
              >mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
  
        <template v-slot:top>
          <v-row class="pa-3" no-gutters>
            <v-col
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'py-3 d-flex align-center justify-center'
                  : 'd-flex align-center'
              "
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <span class="table-title">Relatório de equipamentos</span>
            </v-col>

            <!-- coluna de pesquisa -->
             <v-col
              class="d-flex align-center justify-end"
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <v-text-field
                class="px-2 search-tf"
                style="max-width: 250px"
                outlined
                color="primary"
                hide-details
                dense
                placeholder="Pesquisar..."
                v-model="controller.search"
              ></v-text-field>

              <v-btn
                class="action-btn d-flex justify-center white--text"
                height="40"
                width="120"
                color="green"
                title
                @click.stop="controller.exportExcel()"
                :loading="controller.buttonExcel"
              ><v-icon>mdi-file-excel-outline</v-icon>Excel</v-btn>
            </v-col> 
          </v-row>
        </template>
      </v-data-table>
      <v-dialog
        max-width="600"
        persistent
        scrollable
        v-model="controller.dialogUpdate"
      >
      <v-card class="crud-card">
        <v-card-title class="d-flex justify-center">Adicionar descrição</v-card-title>
        <v-card-text>
          <v-form ref="crud" lazy-validation>
            <v-row>
              <v-col>
                <div class="form-input-group">
                  <v-text-field
                    class="search-tf"
                    single-line
                    id="desc"
                    background-color="transparent"
                    outlined
                    required
                    dense
                    placeholder="Descrição"
                    v-model="controller.form.description"
                  />
                  <label for="desc">Descrição</label>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            outlined
            @click="controller.dialogUpdate = false"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="action-btn d-flex justify-center"
            height="36"
            width="114"
            color="primary"
            tile
            @click="controller.create()"
            :loading="controller.loading"
          >
            <v-icon left>mdi-pencil</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </template>
  
   <script>
  import { equipmentHistoryController } from "@/di/di";
  export default {
    name: "equipmentHistoryPage",
    data: (context) => ({
      controller: equipmentHistoryController(context),
    }),
    watch: {
      "controller.options"() {
        this.controller.watchOptions();
      },
      "controller.search"() {
        this.controller.watchSearch();
      },
      "controller.clientOptions"() {
      this.controller.watchClientOptions();
      },
      "controller.searchClient": _.debounce(function () {
      this.controller.watchClientSearch();
      }, 500),
      "controller.equipmentOptions"() {
        this.controller.watchEquipmentOptions();
      },
      "controller.searchEquipment": _.debounce(function () {
        this.controller.watchEquipmentSearch();
      }, 500),
      "controller.vehicleOptions"() {
        this.controller.watchVehicleOptions();
      },
      "controller.searchVehicle": _.debounce(function () {
        this.controller.watchVehicleSearch();
      }, 500),
      // "controller.modelOptions"() {
      //   this.controller.watchModelOptions();
      // },
      // "controller.searchModel": _.debounce(function () {
      //   this.controller.watchModelSearch();
      // }, 500),
    },
  };
  </script> 
  
  <style scoped lang="scss">
  .astra-table {
    border-radius: 20px;
    background-color: var(--v-secondary-base) !important;
  }
  
  .toolbar-table {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
  
  ::v-deep .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-secondary-base) !important;
  }
  
  .action-btn {
    text-transform: none;
    border-radius: 10px !important;
  }
  
  .search-tf {
    border-radius: 10px !important;
  }
  
  .table-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }
  
  .crud-card {
    border-radius: 20px;
    background-color: var(--v-secondary-base) !important;
  }
  
  ::v-deep .v-dialog {
    border-radius: 20px !important;
  }
  
  label {
    order: -1;
  }
  
  .v-input:focus-within + label {
    color: var(--v-primary-base) !important;
  }
  
  .form-input-group {
    display: flex;
    flex-direction: column;
  }
  
  ::v-deep tbody {
    tr:hover {
      background-color: var(--v-tertiary-base) !important;
    }
  }

  .v-icon.v-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
      font-size: 18px;
      margin-inline: 8px; 
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
  </style>