import { snackbar } from "@/core/controller/snackbar_controller";
import { getAccountName } from "@/core/domain/model/jwtAuthToken";
import { headers } from "../const/headers";
import Account, { accountDefault } from "../domain/account/account";

class AccountController {
  context = null;
  headers = headers
  userName = null
  loading = false
  search = ""
  searchClient = ""
  searchVehicle = ""
  searchEquipment = ""
  dialog = false
  vehiclesAndEquipDialog = false
  form = accountDefault({})
  formEquipVehicle = {}
  equipmentSelected = []
  vehicleSelected = []
  statusSelected = 0
  statusList = [
    { text: "Todos", value: 0 },
    { text: "Ativo", value: 1 },
    { text: "Inativo", value: 2 },
  ]
  emailRules = value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'E-mail inválido.'
  }

  selectedVehicles = []
  selectedEquipments = []

  vehicleHeaders = [
    {
      text: 'Placa',
      align: 'start',
      sortable: true,
      value: 'plate',
    }
  ]

  equipmentHeaders = [
    {
      text: 'Serial',
      align: 'start',
      sortable: true,
      value: 'name',
    },
  ]

  userGroup = [
    { id: 1, name: "Administrador" },
    { id: 2, name: "Cliente administrador" },
    { id: 3, name: "Cliente visualizador" },
    { id: 6, name: "Cliente Produção" },
    { id: 4, name: "Técnico" },
    { id: 5, name: "Produção" }
  ];

  accountPagination = {
    total: 0,
    items: []
  }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  clientPagination = {
    total: 0,
    items: []
  }

  clientOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  vehiclePagination = {
    total: 0,
    items: []
  }

  vehicleOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  equipmentPagination = {
    total: 0,
    items: []
  }

  equipmentOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["name"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }


  constructor(
    context,
    fetchAccountUseCaseImpl,
    createAccountUseCaseImpl,
    changeAccountUseCaseImpl,
    deleteAccountUseCaseImpl,
    fetchClientUseCaseImpl,
    fetchVehicleUseCaseImpl,
    fetchEquipmentUseCaseImpl,
    refreshUseCaseImpl,
    getVisibleEquipmentsByAccountIDUsecaseImpl,
    getVisibleVehiclesByAccountIDUsecaseImpl
  ) {
    this.context = context;
    this.fetchAccountUseCaseImpl = fetchAccountUseCaseImpl
    this.createAccountUseCaseImpl = createAccountUseCaseImpl
    this.changeAccountUseCaseImpl = changeAccountUseCaseImpl
    this.deleteAccountUseCaseImpl = deleteAccountUseCaseImpl
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    this.fetchEquipmentUseCaseImpl = fetchEquipmentUseCaseImpl
    this.refreshUseCaseImpl = refreshUseCaseImpl
    this.getVisibleEquipmentsByAccountIDUsecaseImpl = getVisibleEquipmentsByAccountIDUsecaseImpl
    this.getVisibleVehiclesByAccountIDUsecaseImpl = getVisibleVehiclesByAccountIDUsecaseImpl
  }

  mounted() {
    this.userName = getAccountName()
  }

  async paginate() {
    this.loading = true
    try {
      this.accountPagination = await this.fetchAccountUseCaseImpl(this.options, this.search, this.statusSelected)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async clientPaginate() {
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
    } catch (error) {
      console.log(error);
    }
  }

  async equipmentPaginate() {
    this.equipmentTableLoading = true
    try {
      this.equipmentPagination = await this.fetchEquipmentUseCaseImpl(this.equipmentOptions, this.searchEquipment)
    } catch (error) {
      console.log(error);
    } finally {
      this.equipmentTableLoading = false
    }
  }

  async vehiclePaginate() {
    this.vehicleTableLoading = true
    try {
      this.vehiclePagination = await this.fetchVehicleUseCaseImpl(this.vehicleOptions, this.searchVehicle)
    } catch (error) {
      console.log(error);
    } finally {
      this.vehicleTableLoading = false
    }
  }


  open() {
    this.form = accountDefault({})
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        if (this.form.id != 0) {
          await this.changeAccountUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } else {
          await this.createAccountUseCaseImpl(this.form)
          snackbar.show({ message: "Adicionado com sucesso!!!", color: "#008000", timeout: 3000 })
        }
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialog = false
      }
    }
  }

  change(item) {
    this.dialog = true
    this.form = new Account({ ...item })
    this.searchClient = item.clientName
    this.watchClientSearch()
  }

  async delete(item) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: "primary", timeout: 5000 })
      if (confirm) {
        await this.deleteAccountUseCaseImpl(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: "#008000", timeout: 1000 })
      }
    } catch (error) {
      snackbar.show({ message: error.toString() })
    }
  }


  watchOptions() {
    this.paginate()
  }
  watchSearch() {
    this.paginate()
  }
  watchClientOptions() {
    this.clientPaginate()
  }
  watchClientSearch() {
    this.clientPaginate()
  }
  changeStatus() {
    this.paginate()
  }
  watchVehicleOptions() {
    this.vehiclePaginate()
  }
  watchVehicleSearch() {
    this.vehiclePaginate()
  }
  watchEquipmentOptions() {
    this.equipmentPaginate()
  }
  watchEquipmentSearch() {
    this.equipmentPaginate()
  }

  async changeVehicleEquipment(item) {
    this.equipmentSelected = await this.getVisibleEquipmentsByAccountIDUsecaseImpl(item.id)
    this.vehicleSelected = await this.getVisibleVehiclesByAccountIDUsecaseImpl(item.id)
    this.form = { ...item }
    this.vehiclesAndEquipDialog = true
  }

  isSelectedEquipment(item) {
    return this.equipmentSelected.some((el) => el.id === item.id);
  }
  toggleItemEquipment(item) {
    if (this.equipmentSelected.some((el) => el.id === item.id)) {
      this.removeItemEquipment(item);
    } else {
      this.equipmentSelected.push(item);
    }
  }
  removeItemEquipment(item) {
    if (this.equipmentSelected.some((el) => el.id == item.id)) {
      let index = this.equipmentSelected.map((e) => e.id).indexOf(item.id);
      if (index > -1) {
        this.equipmentSelected.splice(index, 1);
      }
    }
  }

  isSelectedVehicle(item) {
    return this.vehicleSelected.some((el) => el.id === item.id);
  }
  toggleItemVehicle(item) {
    if (this.vehicleSelected.some((el) => el.id === item.id)) {
      this.removeItemVehicle(item);
    } else {
      this.vehicleSelected.push(item);
    }
  }
  removeItemVehicle(item) {
    if (this.vehicleSelected.some((el) => el.id == item.id)) {
      let index = this.vehicleSelected.map((e) => e.id).indexOf(item.id);
      if (index > -1) {
        this.vehicleSelected.splice(index, 1);
      }
    }
  }

  async saveVehicleEquipmentVisibility() {
    this.form.visibleVehicle = this.vehicleSelected.length > 0 ? this.vehicleSelected.map((vehicle) => vehicle.id).join(",") : null
    this.form.visibleEquipment = this.equipmentSelected.length > 0 ? this.equipmentSelected.map((equipment) => equipment.id).join(",") : null
    try {
      await this.changeAccountUseCaseImpl(this.form.id, this.form)
      await this.refreshUseCaseImpl()
      snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
      
    } catch (error) {
      snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
    } finally {
      this.vehiclesAndEquipDialog = false
    }
  }


  async checkboxChange(item) {
    if (!item || item.id === undefined || item.active === undefined) {
      console.error('Item inválido:', item);
      return;
    }
    try {
      this.form = { ...item }
      this.form.active = item.active
      const res = await this.changeAccountUseCaseImpl(this.form.id, this.form)
      
      if(res){
        snackbar.show({ message: "Status alterado com sucesso!", color: "#008000", timeout: 3000 })
        this.paginate()
      }
    } catch (error) {
      snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
    }
  }
}

export default AccountController