import { create, groupBy } from 'lodash';
import { headers } from '../const/headers'
import { vehicleMaintenanceDefault } from '../domain/vehicleMaintenance/vehicleMaintenance'
import * as XLSX from "xlsx";
import { snackbar } from '@/core/controller/snackbar_controller';

class VehicleMaintenanceController {
    context = null;
    headers = headers;
    loading = false;
    loadingBtnExcel = false;
    search = "";
    dialog = false;
    form = vehicleMaintenanceDefault()

    vehicleMaintenancePagination = {
        total: 0,
        items: []
    }

    options = {
        page: 1,
        itemsPerPage: 40,
        groupBy: [],
        sortBy: ["id"],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: []
    }

    clientPagination = {
        total: 0,
        items: []
    }
    
    clientOptions = {
        page: 1,
        itemsPerPage: 40,
        groupBy: [],
        sortBy: ["name"],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: []
    }

    vehiclePagination = {
        total: 0,
        items: []
    }
    
    vehicleOptions = {
        page: 1,
        itemsPerPage: 40,
        groupBy: [],
        sortBy: ["plate"],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortDesc: []
    }

    constructor(
        context,
        fetchVehicleMaintenanceUseCaseImpl,
        createVehicleMaintenanceUseCaseImpl,
        deleteVehicleMaintenanceUseCaseImpl,
        fetchClientUseCaseImpl,
        fetchVehicleUseCaseImpl

    ) {
        this.context = context,
        this.fetchVehicleMaintenanceUseCaseImpl = fetchVehicleMaintenanceUseCaseImpl,
        this.createVehicleMaintenanceUseCaseImpl = createVehicleMaintenanceUseCaseImpl,
        this.deleteVehicleMaintenanceUseCaseImpl = deleteVehicleMaintenanceUseCaseImpl,
        this.fetchClientUseCaseImpl = fetchClientUseCaseImpl,
        this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    }

    async paginate() {
        this.loading = true
        try {
            this.vehicleMaintenancePagination = await this.fetchVehicleMaintenanceUseCaseImpl(this.options, this.search)
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    };

    async clientPaginate() {
        try {
          this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
        } catch (error) {
          console.log(error);
        }
    };

    async vehiclePaginate() {
        try {
          this.vehiclePagination = await this.fetchVehicleUseCaseImpl(this.vehicleOptions, this.searchVehicle)
        } catch (error) {
          console.log(error);
        }
    };

    async delete (item) {
        try {
            const confirm = await snackbar.confirm({ message: 'Deseja realmente excluir o registro?', color: 'primary', timeout: 5000 });
            if (confirm) {
                await this.deleteVehicleMaintenanceUseCaseImpl(item.id);
                this.paginate();
                snackbar.show({ message: 'Registro excluído!', color: '#008000', timeout: 1000 })
            }
        } catch (error) {
            snackbar.show({ message: error.toString() })
        }
    }

    watchOptions() {
        this.paginate()
    }
    watchSearch() {
        this.paginate()
    }
    watchClientOptions() {
        this.clientPaginate()
    }
    watchClientSearch() {
        this.clientPaginate()
    }
    watchVehicleOptions() {
        this.vehiclePaginate()
    }
    watchVehicleSearch() {
        this.vehiclePaginate()
    }

    async exportExcel() {
        try {
          const header = [['ID','ID CLIENTE', 'ID VEICULO', 'DATA DE CRIACAO', 'DESCRICAO', 'NOME DO CLIENTE', 'PLACA', 'ISOLADO', 'ID USUARIO',  'ALTERADO POR']];
          this.loadingBtnExcel = true;
          const paginate = { ...this.options };
          paginate.itemsPerPage = 30000;
          const data = await this.fetchVehicleMaintenanceUseCaseImpl(paginate, "");
          
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, header);
          XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
            const i = JSON.parse(JSON.stringify(item));
            return i;
          }), { origin: 'A2', skipHeader: true });
          XLSX.utils.book_append_sheet(wb, ws, 'vehicleMaintenance');
          
          XLSX.writeFile(wb, `manutencao-veiculos.xlsx`);
        } catch (error) {
          snackbar.show({ message: error.toString() });
        } finally {
          this.loadingBtnExcel = false;
        }
      }
}

export default VehicleMaintenanceController