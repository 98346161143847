export const headers = [
   
    {
        text: 'Cliente',
        sortable: true,
        value: 'clientName'
    },
    {
        text: 'Veículo',
        sortable: true,
        value: 'plate'
    },
    {
        text: 'Data de criação',
        sortable: true,
        value: 'createdDate'
    },
    {
        text: 'Descrição',
        sortable: true,
        value: 'description'
    },
    {
        text: 'Isolado',
        align: 'start',
        sortable: true,
        value: 'isolated',
        width: '5%'
    },
    {
        text: 'Alterado Por',
        sortable: true,
        value: 'account'
    },
    {
        text: 'Ação',
        sortable: false,
        value: 'actions'
    },
]