import { axosInstance, refreshUseCaseImpl } from './axios_instance_di';
//Controllers
import LoginController from '@/module/login/controller/login_controller';
import DefaultController from "@/module/default/controller/default_controller";
import HomeController from '@/module/home/controller/home_controller';
import DashboardController from '@/module/dashboard/controller/dashboard_controller';
import ModelController from '@/module/model/controller/model_controller';
import CodeController from '@/module/code/controller/code_controller';
import ClientController from '@/module/client/controller/client_controller'
import VehicleController from '@/module/vehicle/controller/vehicle_controller';
import EquipmentController from '@/module/equipment/controller/equipment_controller';
import AccountController from '@/module/account/controller/account_controller';
import ReleaseController from '@/module/release/controller/release_controller';
import EquipmentHistoryController from '@/module/equipmentHistory/controller/equipmentHistory_controller';
import VehicleMaintenanceController from '@/module/vehicleMaintenance/controller/vehicleMaintenance_controller';

//Login
import loginRepostory from "@/core/data/repository/login_repository";
import loginUseCase from "@/core/domain/usecase/login_usecase";

const fetchAccountGroupPermissionsRepositoryImpl = fetchAccountGroupPermissionsRepository(axosInstance)
const fetchAccountGroupPermissionsUseCaseImpl = fetchAccountGroupPermissionsUseCase(fetchAccountGroupPermissionsRepositoryImpl)

const loginRepositoryImpl = loginRepostory(axosInstance)
const loginUseCaseImpl = loginUseCase(loginRepositoryImpl)

const getVisibleEquipmentsByAccountIDRepositoryImpl = getVisibleEquipmentsByAccountIDRepository(axosInstance)
const getVisibleEquipmentsByAccountIDUsecaseImpl = getVisibleEquipmentsByAccountIDUsecase(getVisibleEquipmentsByAccountIDRepositoryImpl)

const getVisibleVehiclesByAccountIDRepositoryImpl = getVisibleVehiclesByAccountIDRepository(axosInstance)
const getVisibleVehiclesByAccountIDUsecaseImpl = getVisibleVehiclesByAccountIDUsecase(getVisibleVehiclesByAccountIDRepositoryImpl)


const loginController = (context) => new LoginController(
  context,
  loginUseCaseImpl,
  changeAccountPasswordUseCaseImpl
)

const defaultController = (context) => new DefaultController(
  context,
  refreshUseCaseImpl
);

const homeController = (context) => new HomeController(
  context,
  fetchAccountGroupPermissionsUseCaseImpl
);

const dashboardController = (context) => new DashboardController(
  context
)


//Model
import fetchModelRepository from '@/module/model/data/repository/fetch_model_repository';
import fetchModelUseCase from '@/module/model/domain/usecase/fetch_model_usecase';
import createModelRepository from '@/module/model/data/repository/create_model_repository';
import createModelUseCase from '@/module/model/domain/usecase/create_model_usecase';
import changeModelRepository from '@/module/model/data/repository/change_model_repository';
import changeModelUseCase from '@/module/model/domain/usecase/change_model_usecase';
import deleteModelRepository from '@/module/model/data/repository/delete_model_repository';
import deleteModelUseCase from '@/module/model/domain/usecase/delete_model_usecase';

const fetchModelRepositoryImpl = fetchModelRepository(axosInstance)
const fetchModelUseCaseImpl = fetchModelUseCase(fetchModelRepositoryImpl)
const createModelRepositoryImpl = createModelRepository(axosInstance)
const createModelUseCaseImpl = createModelUseCase(createModelRepositoryImpl)
const changeModelRepositoryImpl = changeModelRepository(axosInstance)
const changeModelUseCaseImpl = changeModelUseCase(changeModelRepositoryImpl)
const deleteModelRepositoryImpl = deleteModelRepository(axosInstance)
const deleteModelUseCaseImpl = deleteModelUseCase(deleteModelRepositoryImpl)

const modelController = (context) => new ModelController(
  context,
  fetchModelUseCaseImpl,
  createModelUseCaseImpl,
  changeModelUseCaseImpl,
  deleteModelUseCaseImpl
)

//EquipmentHistory
import fetchEquipmentHistoryRepository from '@/module/equipmentHistory/data/repository/fetch_equipmentHistory_createdDate'
import fetchEquipmentHistoryUseCase from '@/module/equipmentHistory/domain/usecase/fetch_equipmentHistory_usecase'
import createEquipmentHistoryRepository from '@/module/equipmentHistory/data/repository/create_equipmentHistory_repository'
import createEquipmentHistoryUseCase from '@/module/equipmentHistory/domain/usecase/create_equipmentHistory_usecase'
import getEquipmentHistoryRepository from '@/module/equipmentHistory/data/repository/get_equipmentHistory_repository'
import getEquipmentHistoryUseCase from '@/module/equipmentHistory/domain/usecase/get_equipmentHistory_usecase'
import changeEquipmentHistoryRepository from '@/module/equipmentHistory/data/repository/change_equipmentHistory_repository';
import changeEquipmentHistoryUseCase from '@/module/equipmentHistory/domain/usecase/change_equipmentHistory_usecase';

const fetchEquipmentHistoryRepositoryImpl = fetchEquipmentHistoryRepository(axosInstance)
const fetchEquipmentHistoryUseCaseImpl = fetchEquipmentHistoryUseCase(fetchEquipmentHistoryRepositoryImpl)
const createEquipmentHistoryRepositoryImpl = createEquipmentHistoryRepository(axosInstance)
const createEquipmentHistoryUseCaseImpl = createEquipmentHistoryUseCase(createEquipmentHistoryRepositoryImpl)
const getEquipmentHistoryRepositoryImpl = getEquipmentHistoryRepository(axosInstance)
const getEquipmentHistoryUseCaseImpl = getEquipmentHistoryUseCase(getEquipmentHistoryRepositoryImpl)
const changeEquipmentHistoryRepositoryImpl = changeEquipmentHistoryRepository(axosInstance)
const changeEquipmentHistoryUseCaseImpl = changeEquipmentHistoryUseCase(changeEquipmentHistoryRepositoryImpl)

const equipmentHistoryController = (context) => new EquipmentHistoryController(
  context,
  fetchEquipmentHistoryUseCaseImpl,
  createEquipmentHistoryUseCaseImpl,
  changeEquipmentHistoryUseCaseImpl,
  getEquipmentHistoryUseCaseImpl
)


//Code
import fetchCodeRepository from '@/module/code/data/repository/fetch_code_repository';
import fetchCodeUseCase from '@/module/code/domain/usecase/fetch_code_usecase';
import createCodeRepository from '@/module/code/data/repository/create_code_repository';
import createCodeUseCase from '@/module/code/domain/usecase/create_code_usecase';
import changeCodeRepository from '@/module/code/data/repository/change_code_repository';
import changeCodeUseCase from '@/module/code/domain/usecase/change_code_usecase';
import deleteCodeRepository from '@/module/code/data/repository/delete_code_repository';
import deleteCodeUseCase from '@/module/code/domain/usecase/delete_code_usecase';

const fetchCodeRepositoryImpl = fetchCodeRepository(axosInstance)
const fetchCodeUseCaseImpl = fetchCodeUseCase(fetchCodeRepositoryImpl)
const createCodeRepositoryImpl = createCodeRepository(axosInstance)
const createCodeUseCaseImpl = createCodeUseCase(createCodeRepositoryImpl)
const changeCodeRepositoryImpl = changeCodeRepository(axosInstance)
const changeCodeUseCaseImpl = changeCodeUseCase(changeCodeRepositoryImpl)
const deleteCodeRepositoryImpl = deleteCodeRepository(axosInstance)
const deleteCodeUseCaseImpl = deleteCodeUseCase(deleteCodeRepositoryImpl)

const codeController = (context) => new CodeController(
  context,
  fetchCodeUseCaseImpl,
  createCodeUseCaseImpl,
  changeCodeUseCaseImpl,
  deleteCodeUseCaseImpl
)

//Account
import fetchAccountRepository from '@/module/account/data/repository/fetch_account_repository';
import fetchAccountUseCase from '@/module/account/domain/usecase/fetch_account_usecase';
import createAccountRepository from '@/module/account/data/repository/create_account_repository';
import createAccountUseCase from '@/module/account/domain/usecase/create_account_usecase';
import changeAccountRepository from '@/module/account/data/repository/change_account_repository';
import changeAccountUseCase from '@/module/account/domain/usecase/change_account_usecase';
import deleteAccountRepository from '@/module/account/data/repository/delete_account_repository';
import deleteAccountUseCase from '@/module/account/domain/usecase/delete_account_usecase';

const fetchAccountRepositoryImpl = fetchAccountRepository(axosInstance)
const fetchAccountUseCaseImpl = fetchAccountUseCase(fetchAccountRepositoryImpl)
const createAccountRepositoryImpl = createAccountRepository(axosInstance)
const createAccountUseCaseImpl = createAccountUseCase(createAccountRepositoryImpl)
const changeAccountRepositoryImpl = changeAccountRepository(axosInstance)
const changeAccountUseCaseImpl = changeAccountUseCase(changeAccountRepositoryImpl)
const changeAccountPasswordRepositoryImpl = changeAccountPasswordRepository(axosInstance)
const changeAccountPasswordUseCaseImpl = changeAccountPasswordUseCase(changeAccountPasswordRepositoryImpl)
const deleteAccountRepositoryImpl = deleteAccountRepository(axosInstance)
const deleteAccountUseCaseImpl = deleteAccountUseCase(deleteAccountRepositoryImpl)


const accountController = (context) => new AccountController(
  context,
  fetchAccountUseCaseImpl,
  createAccountUseCaseImpl,
  changeAccountUseCaseImpl,
  deleteAccountUseCaseImpl,
  fetchClientUseCaseImpl,
  fetchVehicleUseCaseImpl,
  fetchEquipmentUseCaseImpl,
  refreshUseCaseImpl,
  getVisibleEquipmentsByAccountIDUsecaseImpl,
  getVisibleVehiclesByAccountIDUsecaseImpl
)


//Client
import fetchClientRepository from '@/module/client/data/repository/fetch_client_repository';
import fetchClientUseCase from '@/module/client/domain/usecase/fetch_client_usecase';
import createClientRepository from '@/module/client/data/repository/create_client_repository';
import createClientUseCase from '@/module/client/domain/usecase/create_client_usecase';
import changeClientRepository from '@/module/client/data/repository/change_client_repository';
import changeClientUseCase from '@/module/client/domain/usecase/change_client_usecase';
import deleteClientRepository from '@/module/client/data/repository/delete_client_repository';
import deleteClientUseCase from '@/module/client/domain/usecase/delete_client_usecase';
import fetchCityRepository from '@/module/client/data/repository/fetch_city_repository';
import fetchCityUseCase from '@/module/client/domain/usecase/fetch_city_usecase';

const fetchClientRepositoryImpl = fetchClientRepository(axosInstance)
const fetchClientUseCaseImpl = fetchClientUseCase(fetchClientRepositoryImpl)
const createClientRepositoryImpl = createClientRepository(axosInstance)
const createClientUseCaseImpl = createClientUseCase(createClientRepositoryImpl)
const changeClientRepositoryImpl = changeClientRepository(axosInstance)
const changeClientUseCaseImpl = changeClientUseCase(changeClientRepositoryImpl)
const deleteClientRepositoryImpl = deleteClientRepository(axosInstance)
const deleteClientUseCaseImpl = deleteClientUseCase(deleteClientRepositoryImpl)
const fetchCityRepositoryImpl = fetchCityRepository(axosInstance)
const fetchCityUseCaseImpl = fetchCityUseCase(fetchCityRepositoryImpl)


const clientController = (context) => new ClientController(
  context,
  fetchClientUseCaseImpl,
  createClientUseCaseImpl,
  changeClientUseCaseImpl,
  deleteClientUseCaseImpl,
  fetchCityUseCaseImpl
)

//Vehicle
import fetchVehicleRepository from '@/module/vehicle/data/repository/fetch_vehicle_repository';
import fetchVehicleUseCase from '@/module/vehicle/domain/usecase/fetch_vehicle_usecase';
import createVehicleRepository from '@/module/vehicle/data/repository/create_vehicle_repository';
import createVehicleUseCase from '@/module/vehicle/domain/usecase/create_vehicle_usecase';
import changeVehicleRepository from '@/module/vehicle/data/repository/change_vehicle_repository';
import changeVehicleUseCase from '@/module/vehicle/domain/usecase/change_vehicle_usecase';
import deleteVehicleRepository from '@/module/vehicle/data/repository/delete_vehicle_repository';
import deleteVehicleUseCase from '@/module/vehicle/domain/usecase/delete_vehicle_usecase';

const fetchVehicleRepositoryImpl = fetchVehicleRepository(axosInstance)
const fetchVehicleUseCaseImpl = fetchVehicleUseCase(fetchVehicleRepositoryImpl)
const createVehicleRepositoryImpl = createVehicleRepository(axosInstance)
const createVehicleUseCaseImpl = createVehicleUseCase(createVehicleRepositoryImpl)
const changeVehicleRepositoryImpl = changeVehicleRepository(axosInstance)
const changeVehicleUseCaseImpl = changeVehicleUseCase(changeVehicleRepositoryImpl)
const deleteVehicleRepositoryImpl = deleteVehicleRepository(axosInstance)
const deleteVehicleUseCaseImpl = deleteVehicleUseCase(deleteVehicleRepositoryImpl)

const vehicleController = (context) => new VehicleController(
  context,
  fetchVehicleUseCaseImpl,
  createVehicleUseCaseImpl,
  changeVehicleUseCaseImpl,
  deleteVehicleUseCaseImpl,
  fetchClientUseCaseImpl,
  fetchEquipmentUseCaseImpl,
  refreshUseCaseImpl,
  createReleaseUseCaseImpl,
  createVehicleMaintenanceUseCaseImpl
)

//Equipment
import fetchEquipmentRepository from '@/module/equipment/data/repository/fetch_equipment_repository';
import fetchEquipmentUseCase from '@/module/equipment/domain/usecase/fetch_equipment_usecase';
import createEquipmentRepository from '@/module/equipment/data/repository/create_equipment_repository';
import createEquipmentUseCase from '@/module/equipment/domain/usecase/create_equipment_usecase';
import changeEquipmentRepository from '@/module/equipment/data/repository/change_equipment_repository';
import changeEquipmentUseCase from '@/module/equipment/domain/usecase/change_equipment_usecase';
import deleteEquipmentRepository from '@/module/equipment/data/repository/delete_equipment_repository';
import deleteEquipmentUseCase from '@/module/equipment/domain/usecase/delete_equipment_usecase';
import fetchEquipmentTypeRepository from '@/module/equipment/data/repository/fetch_equipment_type_repository';
import fetchEquipmentTypeUseCase from '@/module/equipment/domain/usecase/fetch_equipment_type_usecase';
import countEquipmentPlateRepository from '@/module/equipment/data/repository/count_equipment_plate_repository';
import countEquipmentPlateUseCase from '@/module/equipment/domain/usecase/count_equipment_plate_usecase';
import createReleaseRepository from '@/module/equipment/data/repository/create_release_repository';
import createReleaseUseCase from '@/module/equipment/domain/usecase/create_release_usecase';

const fetchEquipmentRepositoryImpl = fetchEquipmentRepository(axosInstance)
const fetchEquipmentUseCaseImpl = fetchEquipmentUseCase(fetchEquipmentRepositoryImpl)
const createEquipmentRepositoryImpl = createEquipmentRepository(axosInstance)
const createEquipmentUseCaseImpl = createEquipmentUseCase(createEquipmentRepositoryImpl)
const changeEquipmentRepositoryImpl = changeEquipmentRepository(axosInstance)
const changeEquipmentUseCaseImpl = changeEquipmentUseCase(changeEquipmentRepositoryImpl)
const deleteEquipmentRepositoryImpl = deleteEquipmentRepository(axosInstance)
const deleteEquipmentUseCaseImpl = deleteEquipmentUseCase(deleteEquipmentRepositoryImpl)
const fetchEquipmentTypeRepositoryImpl = fetchEquipmentTypeRepository(axosInstance)
const fetchEquipmentTypeUseCaseImpl = fetchEquipmentTypeUseCase(fetchEquipmentTypeRepositoryImpl)
const countEquipmentPlateRepositoryImpl = countEquipmentPlateRepository(axosInstance)
const countEquipmentPlateUseCaseImpl = countEquipmentPlateUseCase(countEquipmentPlateRepositoryImpl)
const createReleaseRepositoryImpl = createReleaseRepository(axosInstance)
const createReleaseUseCaseImpl = createReleaseUseCase(createReleaseRepositoryImpl)


const equipmentController = (context) => new EquipmentController(
  context,
  fetchEquipmentUseCaseImpl,
  createEquipmentUseCaseImpl,
  changeEquipmentUseCaseImpl,
  deleteEquipmentUseCaseImpl,
  fetchModelUseCaseImpl,
  fetchVehicleUseCaseImpl,
  fetchEquipmentTypeUseCaseImpl,
  countEquipmentPlateUseCaseImpl,
  fetchClientUseCaseImpl,
  createReleaseUseCaseImpl,
  refreshUseCaseImpl
)

//Release
import fetchReleaseRepository from '@/module/release/data/repository/fetch_release_repository';
import fetchReleaseUseCase from '@/module/release/domain/usecase/fetch_release_usecase';
import fetchAccountGroupPermissionsUseCase from '@/core/domain/usecase/fetch_account_group_permissions_usecase';
import fetchAccountGroupPermissionsRepository from '@/core/data/repository/fetch_account_group_permissions_repository';
import getVisibleEquipmentsByAccountIDRepository from '@/module/equipment/data/repository/get_visible_equipments_by_account_id_repository';
import getVisibleEquipmentsByAccountIDUsecase from '@/module/equipment/domain/usecase/get_visible_equipments_by_account_id_usecase';
import getVisibleVehiclesByAccountIDRepository from '@/module/vehicle/data/repository/get_visible_vehicles_by_account_id_repository';
import getVisibleVehiclesByAccountIDUsecase from '@/module/vehicle/domain/usecase/get_visible_vehicles_by_account_id_usecase';
import changeAccountPasswordRepository from '@/module/account/data/repository/change_account_password_repository';
import changeAccountPasswordUseCase from '@/module/account/domain/usecase/change_account_password_usecase';

const fetchReleaseRepositoryImpl = fetchReleaseRepository(axosInstance)
const fetchReleaseUseCaseImpl = fetchReleaseUseCase(fetchReleaseRepositoryImpl)

const releaseController = (context) => new ReleaseController(
  context,
  fetchReleaseUseCaseImpl,
  fetchAccountUseCaseImpl,
  fetchClientUseCaseImpl,
  fetchCodeUseCaseImpl,
  fetchModelUseCaseImpl,
  fetchVehicleUseCaseImpl,
  fetchEquipmentUseCaseImpl,
)

//VehicleMaintenance
import fetchVechicleMaintenanceRepository from '@/module/vehicleMaintenance/data/repository/fetch_vehicleMaintenance_repository';
import fetchVehicleMaintenanceUseCase from '@/module/vehicleMaintenance/domain/usecase/fetch_vehicleMaintenance_usecase';
import createVehicleMaintenanceRepository from '@/module/vehicleMaintenance/data/repository/create_vehicleMaintenance_repository';
import createVehicleMaintenanceUseCase from '@/module/vehicleMaintenance/domain/usecase/create_vehicleMaintenance_usecase';
import deleteVehicleMaintenanceRepository from '@/module/vehicleMaintenance/data/repository/delete_vehicleMaintenance_repository';
import deleteVehicleMaintenanceUseCase from '@/module/vehicleMaintenance/domain/usecase/delete_vehicleMaintenance_usecase';

const fetchVechicleMaintenanceRepositoryImpl = fetchVechicleMaintenanceRepository(axosInstance)
const fetchVehicleMaintenanceUseCaseImpl = fetchVehicleMaintenanceUseCase(fetchVechicleMaintenanceRepositoryImpl)
const createVehicleMaintenanceRepositoryImpl = createVehicleMaintenanceRepository(axosInstance)
const createVehicleMaintenanceUseCaseImpl = createVehicleMaintenanceUseCase(createVehicleMaintenanceRepositoryImpl)
const deleteVehicleMaintenanceRepositoryImpl = deleteVehicleMaintenanceRepository(axosInstance)
const deleteVehicleMaintenanceUseCaseImpl = deleteVehicleMaintenanceUseCase(deleteVehicleMaintenanceRepositoryImpl)

const vehicleMaintenanceController = (context) => new VehicleMaintenanceController(
  context,
  fetchVehicleMaintenanceUseCaseImpl,
  createVehicleMaintenanceUseCaseImpl,
  deleteVehicleMaintenanceUseCaseImpl,
)

export {
  defaultController,
  loginController,
  homeController,
  dashboardController,
  modelController,
  codeController,
  clientController,
  vehicleController,
  equipmentController,
  accountController,
  releaseController,
  equipmentHistoryController,
  vehicleMaintenanceController
}
