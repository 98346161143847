import Handler from '@/core/domain/usecase/handler';

const fetchVechicleMaintenanceRepository = (axios) => async (pagination) => {
    try {
        const response = await axios.get('/vehicle-maintenance', {
            params: pagination
        })

        const vehicleMaintenace = response.data
        return vehicleMaintenace
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default fetchVechicleMaintenanceRepository