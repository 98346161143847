import { snackbar } from "@/core/controller/snackbar_controller";
import { headers } from "../const/headers";
import { equipmentHistoryDefault } from "../domain/equipmentHistory/equipmentHistory"
import * as XLSX from "xlsx";
import { groupBy } from "lodash";

class EquipmentHistoryController {
    context = null;
    headers = headers;
    loading = false;
    search = "";
    searchClient = "";
    searchEquipment = "";
    searchVehicle = "";
    dialog = false;
    dialogUpdate = false;
    form = equipmentHistoryDefault();
    buttonExcel = false;

    selectedItems = []
    equipmentHistoryPagination = {
      total: 0,
      items: []
    }

  options = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["id"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

    clientPagination = {
      total: 0,
      items: []
    }
    clientOptions = {
      page: 1,
      itemsPerPage: 40,
      groupBy: [],
      sortBy: ["name"],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: []
    }

    equipmentPagination = {
      total: 0,
      items: []
    }  
    equipmentOptions = {
      page: 1,
      itemsPerPage: 40,
      groupBy: [],
      sortBy: ["name"],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: []
    }

    vehiclePagination = {
      total: 0,
      items: []
    }
    vehicleOptions = {
      page: 1,
      itemsPerPage: 40,
      groupBy: [],
      sortBy: ["plate"],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: []
    }

  constructor(
    context,
    fetchEquipmentHistoryUseCaseImpl,
    createEquipmentHistoryUseCaseImpl,
    changeEquipmentHistoryUseCaseImpl,
    getEquipmentHistoryUseCaseImpl,
    fetchClientUseCaseImpl,
    fetchEquipmentUseCaseImpl,
    fetchVehicleUseCaseImpl
  ) {
    this.context = context
    this.fetchEquipmentHistoryUseCaseImpl = fetchEquipmentHistoryUseCaseImpl
    this.createEquipmentHistoryUseCaseImpl = createEquipmentHistoryUseCaseImpl
    this.getEquipmentHistoryUseCaseImpl = getEquipmentHistoryUseCaseImpl
    this.fetchClientUseCaseImpl = fetchClientUseCaseImpl
    this.fetchEquipmentUseCaseImpl = fetchEquipmentUseCaseImpl
    this.fetchVehicleUseCaseImpl = fetchVehicleUseCaseImpl
    this.changeEquipmentHistoryUseCaseImpl = changeEquipmentHistoryUseCaseImpl
  }

  async paginate() {
    this.loading = true
    try {
      this.equipmentHistoryPagination = await this.fetchEquipmentHistoryUseCaseImpl(this.options, this.search, this.form);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  async clientPaginate() {
    try {
      this.clientPagination = await this.fetchClientUseCaseImpl(this.clientOptions, this.searchClient)
    } catch (error) {
      console.log(error);
    }
  }

  async vehiclePaginate() {
    try {
      this.vehiclePagination = await this.fetchVehicleUseCaseImpl(this.vehicleOptions, this.searchVehicle)
    } catch (error) {
      console.log(error);
    }
  }

  async equipmentPaginate() {
    try {
      this.equipmentPagination = await this.fetchEquipmentUseCaseImpl(this.equipmentOptions, this.searchEquipment)
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  }

  // open() {
  //   this.form = equipmentHistoryDefault();
  // }
  
  //filtro de pesquisa
  // async filter() {
  //   try {
  //     this.equipmentHistoryPagination = await this.fetchEquipmentHistoryUseCaseImpl(this.options, this.search, this.form)
      
  //     this.dialog = false;
      
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async change(item) {
    this.dialogUpdate = true
    this.form = { ...item }
  }

  async create(){
    if (this.context.$refs.crud.validate()) {
      try {
        this.loading = true
        if (this.form.id != 0) {
          await this.changeEquipmentHistoryUseCaseImpl(this.form.id, this.form)
          snackbar.show({ message: "Alterado com sucesso!!!", color: "#008000", timeout: 3000 })
        } 
      } catch (error) {
        snackbar.show({ message: error.toString(), color: "#ff0000", timeout: 3000 })
      } finally {
        this.paginate()
        this.loading = false
        this.dialogUpdate = false
      }
    }
  }

  async exportExcel () {
    try {
      const header = [['ID', 'Cliente', 'Veículo', 'Equipamento', 'Modelo', 'DATA DE CRIAÇÃO', 'DESCRIÇÃO', 'NOME DO CLIENTE', 'PLACA', 'NOME DO EQUIPAMENTO']];
      this.buttonExcel = true;
      const paginate = { ...this.options };
      paginate.itemsPerPage = 30000;
      const data = await this.fetchEquipmentHistoryUseCaseImpl(paginate, "");
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, header);
      XLSX.utils.sheet_add_json(ws, data.items.map((item) => {
        const i = JSON.parse(JSON.stringify(item));
        return i;
      }), { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'equipmentHistory');
      XLSX.writeFile(wb, `equipmentHistory.xlsx`);
    } catch (error) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.buttonExcel = false;
    }
  }
  watchOptions() {
    this.paginate();
  }
  watchSearch() {
    this.paginate();
  }
  watchClientOptions() {
    this.clientPaginate();
  }
  watchClientSearch() {
    this.clientPaginate();
  }
  watchEquipmentOptions() {
    this.equipmentPaginate();
  }
  watchEquipmentSearch() {
    this.equipmentPaginate();
  }
  watchVehicleOptions() {
    this.vehiclePaginate();
  }
  watchVehicleSearch() {
    this.vehiclePaginate();
  }
}

export default EquipmentHistoryController