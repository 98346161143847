import Handler from '@/core/domain/usecase/handler';

const deleteVehicleMaintenanceRepository = (axios) => async (id) => {
    try {
        const response = await axios.delete(`/vehicle-maintenance/${id}`)
        const maintenance = response.data
        return maintenance
    } catch (err) {
        if (err.isAxiosError) {
            throw new Handler().trataErro(err)
        } else {
            throw err
        }
    }
}

export default deleteVehicleMaintenanceRepository