import Handler from "@/core/domain/usecase/handler";

const fetchEquipmentHistoryCreatedDateRepository = (axios) => async (pagination) => {
    try {
      const response = await axios.get(`/equipment-history`, {
        params: pagination
      })
      return response.data
    } catch (err) {
      if (err.isAxiosError) {
        throw new Handler().trataErro(err)
      } else {
        throw err
      }
    }
  }
  
  export default fetchEquipmentHistoryCreatedDateRepository