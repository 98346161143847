<template>
    <div>
        <v-data-table
            class="astra-table"
            dense
            height="76vh"
            fixed-header
            :headers="controller.headers"
            :options.sync="controller.options"
            :server-items-length="controller.vehicleMaintenancePagination.total"
            :items="controller.vehicleMaintenancePagination.items"
            :loading="controller.loading"
            :footer-props="{
                'items-per-page-options': [40, 80],
            }"
        >
            <template v-slot:[`item.isolated`]="{ item }">
                <v-checkbox
                    color="primary"
                    class="mt-0 pt-0"
                    v-model="item.isolated"
                    hide-details
                    readonly
                    dense
                ></v-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
               <div>
                <v-btn 
                    v-if="hadPermission(1,0)"
                    icon 
                    @click="controller.delete(item)" 
                    class="px-2">
                    <v-icon color="deleteBtn" small>mdi-delete</v-icon>
                </v-btn>
               </div>
            </template>

            <template v-slot:top>
                <v-row class="pa-3" no-gutters>
                    <v-col
                        :class="
                            $vuetify.breakpoint.smAndDown
                            ? 'py-3 d-flex align-center justify-center'
                            : 'd-flex align-center'
                        "
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        >
                        <span class="table-title">Registro de atendimentos</span>
                        </v-col>

                        <v-col
                        class="d-flex align-center justify-end"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        >
                            <v-text-field
                                class="px-2 search-tf"
                                style="max-width: 250px"
                                outlined
                                color="primary"
                                hide-details
                                dense
                                placeholder="Pesquisar..."
                                v-model="controller.search"
                            ></v-text-field>
                            <v-btn
                            class="action-btn d-flex justify-center white--text"
                            height="40"
                            width="120"
                            color="green"
                            tile
                            @click.stop="controller.exportExcel()"
                            :loading="controller.loadingBtnExcel"
                            >
                            <v-icon left>mdi-file-excel-outline</v-icon>
                            Excel   
                            </v-btn>
                    </v-col> 
                </v-row>
            </template>
            <template v-slot:[`item.createdDate`] = "{ item }">
                {{ dayjs(item.createdDate).format("DD/MM/YYYY HH:mm") }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vehicleMaintenanceController } from '@/di/di';
    import dayjs from 'dayjs';

    export default {
        name: 'vehicleMaintenance-page',
        data: (context) => ({
            controller: vehicleMaintenanceController(context),
        }),
        watch: {
            "controller.options"() {
                this.controller.watchOptions();
            },
            "controller.search"() {
                this.controller.watchSearch();
            },
            "controller.clientOptions"() {
                this.controller.watchClientOptions();
            },
            "controller.searchClient": _.debounce(function () {
                this.controller.watchClientSearch();
            }, 500),
            "controller.vehicleOptions"() {
                this.controller.watchVehicleOptions();
            },
            "controller.searchVehicle": _.debounce(function () {
                this.controller.watchVehicleSearch();
            }, 500),
        }
    }
</script>

<style scoped lang="scss">
    .astra-table {
        border-radius: 20px;
        background-color: var(--v-secondary-base) !important;
    }

    .toolbar-table {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }

    ::v-deep .v-data-table.v-data-table--fixed-header thead th {
        background-color: var(--v-secondary-base) !important;
    }

    .action-btn {
        text-transform: none;
        border-radius: 10px !important;
    }

    .search-tf {
        border-radius: 10px !important;
    }

    .table-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
    }

    .crud-card {
        border-radius: 20px;
        background-color: var(--v-secondary-base) !important;
    }

    ::v-deep tbody {
        tr:hover {
            background-color: var(--v-tertiary-base) !important;
        }
    }
</style>